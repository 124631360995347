<template>
	<div class="right-box">
		<dataplat-search @submit="handleSubmit" ref="dataplatSearch"></dataplat-search>
		<DownloadBtn @exportData="exportData" :id="1" :type=1 :style="{
        position: 'relative',
        left: 0,
        'margin-bottom': '10px',
        'background-color': 'rgba(241, 110, 113, 1)',
      }"></DownloadBtn>
		<dataplat-content :columns="tableColumns" :list="tableList" :total="total" @changePage="handleChangePage"
			@changePageSize="handleChangePageSize"></dataplat-content>
	</div>
</template>

<script>
	import SearchPanel from "./components/SearchPanel";
	import ContentPanel from "@/components/ContentPanel";
	import DownloadBtn from "@/components/DownloadBtn";
	import {
		post_enterprise_jbsgList
	} from "@/api/houseEnterprise";
	import {
		downloadData,
		formatJson
	} from "@/utils/index";
	import dataReports from "@/mixins/dataReports";
	export default {
		name: "mergerAndAcquisition",
		mixins: [dataReports],
		components: {
			"dataplat-search": SearchPanel,
			"dataplat-content": ContentPanel,
			DownloadBtn,
		},
		computed: {
			code() {
				return this.$store.state.reHouseEnterprise.selectedCompantGpmc
			},
		},
		watch: {
			code(newVal, oldVal) {
				// if (newVal !== oldVal) {
				// 	this.$store.state.componeyCode = newVal;
				// 	const that = this;
				// 	if (that.code.length !== 0) {
				// 		this.$refs.dataplatSearch.handleSearch();
				// 	}
				// }
				this.$refs.dataplatSearch.handleSearch();
			},
		},
		data() {
			return {
				tableColumns: [],
				tableList: [],
				total: 0,
				searchForm: {},
			};
		},
		beforeMount() {
			this.$Notice.destroy();
		},
		mounted() {
			const that = this;
			setTimeout(function() {
				if (that.code.length !== 0) {
					that.$refs.dataplatSearch.handleSearch();
				}
			}, 500);


		},
		methods: {
			handleChangePage(page) {
				this.searchForm.current = page;
				this.handleSubmit(this.searchForm);
			},
			exportData() {
				const columns = [{
						title: "公告日期",
						key: "ggrq",
						ellipsis: true,

						tooltip: true,

						align: "center",
					},
					{
						title: "并购方式",
						key: "bgfs",
						ellipsis: true,

						tooltip: true,

						align: "center",
					},
					{
						title: "收购方/受让方",
						key: "sgfSrf",
						ellipsis: true,

						tooltip: true,

						align: "center",
					},
					{
						title: "交易金额(亿元)",
						key: "jyje",
						ellipsis: true,
						tooltip: true,
						align: "center",
						render: (h, params) => {
							return h(
								"span",
								params.row.jyje ?
								isNaN(params.row.jyje) ?
								params.row.jyje :
								Number(params.row.jyje).toFixed(2) :
								params.row.jyje
							);
						},
					},
					{
						title: "交易标的",
						key: "jybd",
						ellipsis: true,

						tooltip: true,

						align: "center",
					},
				];
				const params = this.searchForm;
				params.size = 1000000;
				params.current = 1;
				let requestParam = Object.assign(params, {
					code: this.code, // 股票代码
				});
				post_enterprise_jbsgList(requestParam)
					.then((data) => {
						let filterVal = [];
						let tHeader = [];
						columns.forEach((item) => {
							tHeader.push(item.title);
							filterVal.push(item.key);
						});
						const resData = formatJson(filterVal, data.records);
						downloadData(tHeader, resData, "兼并收购");
					})
					.catch((e) => {

					});
			},
			handleChangePageSize(page) {
				this.searchForm.size = page;
				this.handleSubmit(this.searchForm);
			},
			handleSubmit(params) {
				this.searchForm = params;
				let requestParam = Object.assign(params, {
					code: this.code, // 股票代码
				});
				const columns = [{
						title: "公告日期",
						ellipsis: true,

						tooltip: true,

						align: "center",
						key: "ggrq",
					},
					{
						title: "并购方式",
						align: "center",
						ellipsis: true,

						tooltip: true,

						key: "bgfs",
					},
					{
						title: "收购方/受让方",
						align: "center",
						ellipsis: true,

						tooltip: true,

						key: "sgfSrf",
					},
					{
						title: "交易金额(亿元)",
						align: "center",
						ellipsis: true,

						tooltip: true,

						key: "jyje",
						render: (h, params) => {
							return h(
								"span",
								params.row.jyje ?
								isNaN(params.row.jyje) ?
								params.row.jyje :
								Number(params.row.jyje).toFixed(2) :
								params.row.jyje
							);
						},
					},
					{
						title: "交易标的",
						align: "center",
						ellipsis: true,

						tooltip: true,

						key: "jybd",
					},
					{
						title: "兼并收购详情",
						align: "center",
						ellipsis: true,
						tooltip: true,
						key: "action",
						width: 160,
						fixed: "right",
						render: (h, params) => {
							return h("div", [
								h(
									"Button", {
										on: {
											click: () => this.handleViewMore(params.row.id),
										},
									},
									"查看详细"
								),
							]);
						},
					},
				];
				post_enterprise_jbsgList(requestParam)
					.then((data) => {
						this.tableColumns = columns;
						this.total = data.total;
						this.tableList = data.records || [];
					})
					.catch((e) => {

					});
			},
			handleViewMore(id) {
				this.$router.push({
					path: `/Insight/enterprise/enterpriseHome/reHouseEnterprise/mergerAcquisitionDetails`,
					query: {
						id: id,
					},
				});
			},
		},
	};
</script>
