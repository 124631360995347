<template>
    <div class="home">
        <Card>
            <Form :model="searchForm" :label-width="120" inline>
                <FormItem label="交易标的">
                    <Input
                        name="jybd"
                        v-model="searchForm.jybd"
                        placeholder="交易标的"
                        @on-change="(event) => this.handleChangeOption('文件名称', 'string', 'jybd', event.target.value)"
                    />
                </FormItem>
                <FormItem label="日期">
                    <DateRange
                        v-model="searchForm.rq"
                        @change="(value) => this.handleChangeOption('日期', 'date', 'rq', value)"
                    ></DateRange>
                </FormItem>
                <FormItem>
                    <Button class="searchBtnLog" data-id="1" type="primary" @click="handleSearch">查询</Button>
                </FormItem>
            </Form>
        </Card>
        <div class="tagbox">
            <template v-if="Object.keys(selectedList).length !== 0">
                已选条件：
                <template v-for="item in selectedList">
                    <template v-if="item !== null">
                        <Tag
                            :key="item.formItem"
                            type="border"
                            closable
                            @on-close="() => handleClose(item.formItem)"
                        >
                            <span v-if="item">{{item.title}}:</span>
                            <span v-for="name in item.data" :key="name">{{name}}</span>
                        </Tag>
                    </template>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import { post_enterprise_tjkj } from '@/api/houseEnterprise'
import tagBoxMixin from '@/views/share/tagBoxMixin'
import dateHandleMixin from '@/views/share/dateHandleMixin'
import { checkDate } from '@/utils/index'
export default {
    name: 'SearchPanel',
    components: {
    },
    mixins: [tagBoxMixin, dateHandleMixin],
    data() {
        return {
            searchForm: {
                rq: ['', ''], // 日期
                jybd: '', // 标题
                size: 10,
                current: 1,
            },
            selectedList: {} //已选条件
        }
    },
    methods: {
        handleSearch() {
            const { rq } = this.searchForm;
            if (!checkDate(rq[0], rq[1])) {
                 this.$msg.error({ type: "remind", code: 4015 });

                return;
            }
            let searchForm = this.handleDateRule(rq, 'rq', '日期', this.searchForm);
            if (searchForm === null) {
                return;
            }
            if (Array.isArray(searchForm.jybd)) {
                searchForm.jybd = ''
            }
            this.$emit('submit', searchForm);
        }
    }
}
</script>
